import React from 'react'
// Images
import SeeingHands from '../images/SeeingHands.jpg'
import KsenianMatka from '../images/KsenianMatka.jpg'
import LifeOfRomu from '../images/LifeofRomu280.jpg'
import Kannattelija from '../images/Kannattelija-2.jpg'
import Village from '../images/Village280.jpg'
import FromUnderTheNorthStar from '../images/From-under-the-North-Star.jpg'
import HeadlessSaint from '../images/Päätön pyhimys etsii sieluaan.jpg'
import AjatusAaltoja from '../images/ajatusaaltoja.jpeg'
import April20th from '../images/april20th.jpeg'
import Arkku from '../images/arkku2.jpeg'
import ChildrenKingdom from '../images/childrenkingdom.jpeg'
import AsABaby from '../images/as-a-baby.jpeg'
import Forgotten from '../images/forgotten.jpeg'

import Encounters from '../images/Encounters.jpg'
import Taivaallista from '../images/taivaallista musiikkia 1.jpg'
import Taivaallistax from '../images/taivaallista musiikkia 2.jpg'
import VuoteemmeVirta from '../images/vuoteemmeVirta.jpg'

import AskTheAnimals from '../images/askTheAnimals.jpg'
import PuutPurjeina from '../images/puutpurjeina.jpg'
import Outo from '../images/outojaihana.jpeg'
import Lintuja from '../images/Lintuja.jpg'

import DogAndAngel from '../images/koira ja enkeli.jpg'
import HeavenlyMusic from '../images/taivaallista musiikkia 1.jpg'
import InLight from '../images/Valossa.jpg'

function Works() {
  return (
    <div className='container mx-auto w-full shadow-xl py-16'>
        <h1 className='text-4xl text-center py-3 font-bold' id='woodcuts'>Works</h1>
        <div className='w-full px-0 sm:px-2 flex flex-wrap justify-center'>
            <div className='title'>
                <h1 className='text-2xl font-bold block'>Woodcuts in color</h1>
            </div>
            <div className='flex flex-wrap py-2 w-full justify-center'>
                <div className='mx-2 w-96'>
                    <img className='rounded' src={InLight} alt='valossa'>
                    </img>
                    <p className='text-center'>Valossa</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded' src={DogAndAngel} alt='koira ja enkeli'>
                    </img>
                    <p className='text-center'>Koira ja enkeli</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded' src={Kannattelija} alt='carrying the world'>
                    </img>
                    <p className='text-center'>Carrying the World - 2018</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded' src={Village} alt='village'></img> 
                    <p className='text-center'>Village - 2020</p>
                </div>
                
                <div className='mx-2 w-96'>
                    <img className='rounded' src={AjatusAaltoja} alt='ksenia'></img> 
                    <p className='text-center'>Ajatus aaltoja - 2020</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded sm:h-96 w-full sm:w-full' src={VuoteemmeVirta} alt='saint'>
                    </img>
                    <p className='text-center'>Vuoteemme virta - 2024</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded sm:h-96 w-full sm:w-full' src={HeadlessSaint} alt='saint'>
                    </img>
                    <p className='text-center'>Headless saint - 2020</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded sm:h-96 w-full sm:w-full' src={Encounters} alt='saint'>
                    </img>
                    <p className='text-center'>Encounters - 2024</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded sm:h-96 w-full sm:w-full' src={Taivaallista} alt='saint'>
                    </img>
                    <p className='text-center'>Taivaallista muusiikkia - 2024</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded sm:h-96 w-full sm:w-full' src={Taivaallistax} alt='saint'>
                    </img>
                    <p className='text-center'>Taivaallista muusiikkia 2 - 2024</p>
                </div>
                
            </div>
        </div>
        <h1 className='anchor py-8' id='peaceable'></h1>
        <div className='w-full px-0 sm:px-2 flex flex-wrap justify-center'>
            <div className='title'>
                <h1 className='text-2xl font-bold block'>The Peaceable Kingdom</h1>
            </div>
            <div className='flex flex-wrap py-2 w-full justify-center'>
                <div className='mx-2 w-96'>
                    <img className='rounded w-96' src={April20th} alt='april20th'>
                    </img>
                    <p className='text-center'>April 20th - 2013</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded w-96' src={Arkku} alt='arkku'></img> 
                    <p className='text-center'>Arkku - 2013</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded w-96 h-64' src={ChildrenKingdom} alt='children'>
                    </img>
                    <p className='text-center'>Children - 2013</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded w-96 h-64' src={AsABaby} alt='asababy'></img> 
                    <p className='text-center'>As a baby - 2013</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded w-96' src={Forgotten} alt='forgotten'></img> 
                    <p className='text-center'>Forgotten - 2013</p>
                </div>
            </div>
        </div>
        <h1 className='anchor py-8' id='askTheAnimals'></h1>
        <div className='w-full px-0 sm:px-2 flex flex-wrap justify-center'>
            <div className='title'>
                <h1 className='text-2xl font-bold block'>Ask the animals</h1>
            </div>
            <div className='flex flex-wrap py-2 w-full justify center'>
                <div className='mx-2 w-96'>
                    <img className='rounded w-96 h-64' alt='lintuja' src={Lintuja}></img>
                    <p className='text-center'>Lintuja</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded w-96' alt='puut purjeina' src={PuutPurjeina}></img>
                    <p className='text-center'>Puut purjeina</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded w-96' alt='outo ja ihana' src={Outo}></img>
                    <p className='text-center'>Outo ja ihana</p>
                </div>
                <div className='mx-2 w-96'>
                    <img className='rounded sm:h-96 w-full sm:w-full' alt='kysy eläimiltä' src={AskTheAnimals}></img>
                    <p className='text-center'>Kysy eläimiltä</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Works