import React from 'react'
import AboutImg from '../images/about.jpg'

function About() {
  return (
    <div className='container mx-auto w-screen py-16 shadow-xl'>
        <h1 className='text-4xl text-center py-3 font-bold'>Jaana Erkkilä-Hill</h1>
        <div className='flex flex-wrap'>
          <div className='md:w-1/4'>
              <img className='rounded' src={AboutImg} alt='about'></img>
          </div>
          <div className='md:w-3/4 pl-2'>
            <p>
            Jaana Erkkilä(-Hill) is a visual artist, researcher and professor of visual arts at the University of Lapland. Her more than thirty years of artistic work consists of works realized with different methods of printmaking. Woodcuts and linocuts are central to Erkkilä's work, and the knowledge of the hand, the feel of the material are important elements for her during the working process. As a printmaker, Erkkilä has never been the creator of large series of prints but has focused on finding the possibilities of visual language in her works. To achieve the final result, even one successful print out of numerous experiments is enough.
            </p>
            <br></br>
            <p>
            In her visual search for the invisible Jaana Erkkilä combines the everyday and the sacred, the visible and the invisible world. Although the motifs of the works move along the lines of spirituality and nature mysticism, the central theme is also color. The layering of different levels is emphasized by the covering and revealing nature of the colors and shapes cut on wood and lino blocks and then printed on paper or fabric. The images are created from several color layers that can be seen through each other, and the atmosphere is created from the interaction of colors alongside the presenting subject, a process that combines the skills of the hand, the observations of the eye and, at the bottom of all the infinity, years of experience and stable judgment.
            </p>
            <br></br>
            <p>
            Erkkilä has been teaching visual arts since her student years. In recent years, she has focused her research activities especially on artist pedagogy. She investigates how artists who are actively engaged in their own artistic work teach art, and how the experiential knowledge related to the teacher's own artistic work is transferred by the students to their own growth platform.
            </p>
            <br></br>
            
          </div>
        </div>
    </div>
  )
}

export default About